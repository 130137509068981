import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseTypeData } from '../types';
import { Observable } from 'rxjs';
import { AppService } from './app.service';

@Injectable()
export class HttpService {
  constructor(public http: HttpClient, private appService: AppService) {}

  private getTargetHeaders(data: any) {
    const target = {
      'Content-Type': 'application/json',
    };

    return target;
  }

  $httpGet(url: string, headers: any = {}): Observable<any> {
    const httpOptions: any = {
      headers: new HttpHeaders(
        Object.assign(
          {
            'Content-Type': 'application/json',
          },
          headers
        )
      ),
    };
    if (headers.responseType) {
      httpOptions.responseType = headers.responseType;
    }
    return this.http.get(url, httpOptions);
  }

  $httpPut(url, data, headers: any = {}) {
    const httpOptions: any = {
      headers: new HttpHeaders(Object.assign(this.getTargetHeaders(data), headers)),
    };
    if (headers.responseType) {
      httpOptions.responseType = headers.responseType;
    }
    return this.http.put(url, data, httpOptions);
  }

  $httpPatch(url, data, headers: any = {}) {
    const httpOptions: any = {
      headers: new HttpHeaders(Object.assign(this.getTargetHeaders(data), headers)),
    };
    if (headers.responseType) {
      httpOptions.responseType = headers.responseType;
    }
    return this.http.patch(url, data, httpOptions);
  }

  $httpPost(url, data, headers: any = {}) {
    const httpOptions: any = {
      headers: new HttpHeaders(Object.assign(this.getTargetHeaders(data), headers)),
    };
    if (headers.responseType) {
      httpOptions.responseType = headers.responseType;
    }
    return this.http.post(url, data, httpOptions);
  }

  $httpDelete(url: string, data: any, headers: any = {}) {
    const httpOptions = {
      headers: new HttpHeaders(this.getTargetHeaders(data)),
      body: data,
    };
    const responseTypeData: ResponseTypeData = {};
    if (headers.responseType) {
      responseTypeData.responseType = headers.responseType;
    }
    return this.http.delete(url, { ...httpOptions, ...responseTypeData });
  }
}
