import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-rentmanager',
  templateUrl: './rentmanager.component.html',
  styleUrls: ['./rentmanager.component.scss'],
})
export class RentmanagerComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<RentmanagerComponent>) {}

  onSubmit(data: string) {
    console.log(data);
    this.dialogRef.close(data);
  }

  ngOnInit(): void {}
}
