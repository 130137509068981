import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../components/alert/alert.component';
import { PromptComponent } from '../components/prompt/prompt.component';
import { LoadingComponent } from '../components/loading/loading.component';
import { ConfirmComponent } from '../components/confirm/confirm.component';
import { Router } from '@angular/router';
import { ConfirmConfig, ImagePickerConfig } from '../types';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { AlphabeticCountriesCodes } from '../consts/alphabetic-countries-codes';
import { ImagePickerComponent } from '../components/image-picker/image-picker.component';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ToolsService {
  NS_URL: string = environment.NS_URL;
  month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  passwordPattern: any = new RegExp('^(?=.*[a-z])|(?=.*[A-Z])(?=.*[0-9])|(?=.*[!@#$%^&*])(?=.{6,})');

  constructor(public snackBar: MatSnackBar, public dialog: MatDialog, public router: Router) {}

  public EURIC(url: string, queries: object): string {
    let str = '';
    for (const key in queries) {
      if (queries.hasOwnProperty(key)) {
        str !== '' ? (str += '&') : null;
        str += `${key}=${encodeURIComponent(queries[key])}`;
      }
    }
    return `${url}?${str}`;
  }

  public setPhone(phoneNumber): string {
    phoneNumber = (phoneNumber || '').trim();
    return phoneNumber.length === 10 ? `1${phoneNumber}` : '';
  }

  public uploadPhoto = async (options: ImagePickerConfig): Promise<string> => {
    const dialogRef = this.dialog.open(ImagePickerComponent, {
      data: {
        format: 'png',
        size: 512,
        maintainAspectRatio: true,
        ...options,
      },
      panelClass: 'image-uploader-model',
    });
    return await dialogRef.afterClosed().toPromise();
  };

  public showPhotoSelectionDialog(options: ImagePickerConfig): Observable<Blob> {
    const dialogRef = this.dialog.open(ImagePickerComponent, {
      data: {
        format: 'png',
        size: 512,
        maintainAspectRatio: true,
        returnBlob: true,
        ...options,
      },
      panelClass: 'image-uploader-model',
      maxWidth: '50%',
    });
    return dialogRef.afterClosed();
  }

  public confirm = async (data: ConfirmConfig): Promise<boolean> => {
    return await this.dialog.open(ConfirmComponent, { data }).afterClosed().toPromise();
  };

  prompt(data): Promise<string | null> {
    return new Promise((resolve: any) =>
      this.dialog
        .open(PromptComponent, { data })
        .afterClosed()
        .subscribe((result) => resolve(result))
    );
  }

  alert(title, message, button?) {
    this.dialog.open(AlertComponent, { data: { title, message, button } });
  }

  toast(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 2000 });
  }

  public loading(type = false, message?: string): void {
    const dialogRef = this.dialog.getDialogById('loading-dialog');

    if (type && !dialogRef) {
      this.dialog.open(LoadingComponent, { data: { message }, id: 'loading-dialog' });
    } else if (!type && dialogRef) {
      dialogRef.close();
    }
  }
  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  comparefirstName(a, b) {
    if (a?.firstName?.toLowerCase() < b?.firstName?.toLowerCase()) {
      return -1;
    }
    if (a?.firstName?.toLowerCase() > b?.firstName?.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  compareIndex(a, b) {
    if (a.index < b.index) {
      return -1;
    }
    if (a.index > b.index) {
      return 1;
    }
    return 0;
  }

  removeItem(arr, item) {
    return arr.filter((f) => f !== item);
  }

  public sortNumber(a, b, isAsc): number {
    a = Number(a || 0);
    b = Number(b || 0);
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public compare(a: number | string, b: number | string, isAsc: boolean): number {
    a = a || '';
    b = b || '';
    if (typeof a === 'string') a = a.toLowerCase().trim();
    if (typeof b === 'string') b = b.toLowerCase().trim();

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public getPathStorageFromUrl(url: string): string {
    let baseUrl = url.split('/o/')[0];
    baseUrl = baseUrl + '/o/';
    let imagePath: string = url.replace(baseUrl, '');
    const indexOfEndPath = imagePath.indexOf('?');
    imagePath = imagePath.substring(0, indexOfEndPath);
    imagePath = imagePath.split('%2F').join('/');
    imagePath = imagePath.split('%20').join(' ');
    return imagePath;
  }

  public doLogout(): void {
    localStorage.clear();
    location.reload();
  }

  public changeRoute(queryParams: object, path?: string): void {
    this.router.navigate(path ? [path] : [], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  public getPhoneNumberInE164Format(num: string): string {
    if (num) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const PNF = PhoneNumberFormat;
      const number = phoneUtil.parseAndKeepRawInput(num, AlphabeticCountriesCodes.US);
      return phoneUtil.format(number, PNF.E164);
    }
    return num;
  }

  public getPhoneNumberInNationalFormat(num: string): string {
    if (num) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const PNF = PhoneNumberFormat;
      const number = phoneUtil.parseAndKeepRawInput(num, AlphabeticCountriesCodes.US);
      return phoneUtil.format(number, PNF.NATIONAL);
    }
    return num;
  }

  public isValidPhoneNumber(phoneNumber: string): boolean {
    if (!phoneNumber) return false;
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const parsedPhoneNumber = phoneUtil.parse(phoneNumber, AlphabeticCountriesCodes.US);
      return phoneUtil.isValidNumber(parsedPhoneNumber);
    } catch (error) {
      console.error('Error parsing phone number:', error);
      return false;
    }
  }

  addDateSuffix(name: string): string {
    const dateSuffix = moment().format('YYYY-MM-DD');
    return `${name}_${dateSuffix}`;
  }

  public goBack(): void {
    history.back();
  }
}
