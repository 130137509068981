<div class="container">
  <div class="row">
    <form (submit)="onSubmit(company.value + '/' + token.value + '/' + location.value)">
      <div class="field-wrap">
        <h3>Auth Token and Location</h3>
        <input #company placeholder=" RM Company" /><br /><br />
        <input #token placeholder="Token" /><br /><br />
        <input #location placeholder="Location" /><br /><br />
        <button mat-raised-button color="primary" type="subbmit">Confirm</button>
      </div>
    </form>
  </div>
</div>
