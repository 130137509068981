/**
 * Check if the given email address is valid.
 *
 * @param {string} email - The email address to validate.
 * @returns {boolean} - True if the email is valid, false otherwise.
 */
const isValidEmail = (email: string): boolean => {
  const emailRegex = /^([\w\.\-\+]+)@([\w\-]+(\.[\w\-]+)*\.(\w{2,25}))$/; // Regular expression pattern to validate email
  return emailRegex.test(email); // Test if the email matches the pattern
};

export default {
  isValidEmail,
};
