enum DashboardChartsSettingNames {
  CHART_SETTINGS = 'chartSettings',
  EVICTION_CHART_SETTINGS = 'evictionChartSettings',
}

const DAYS_OF_MONTH: number[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
];

const YEARS = Array.from({ length: 51 }, (_, i) => new Date().getFullYear() - i);

const DEFAULT_UNTIL_DAY: string = undefined;

const MINUTE_IN_MILLISECONDS: number = 60000;
const PAGINATOR_PAGE_SIZES: number[] = [5, 10, 20, 50, 100];
const MIN_MINUTES_SINCE_LAST_SYNC_PRIOR_SEQUENCE_MESSAGE = 180;

enum Softwares {
  ENTRATA = 'Entrata',
  YARDI = 'Yardi',
  RENT_MANAGER = 'Rent Manager',
  REAL_PAGE = 'RealPage',
  RESMAN = 'ResMan',
  MRI = 'MRI',
  APPFOLIO = 'Appfolio',
}

enum NotificationPace {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

enum LedgerFieldNames {
  NINTY_DAYS_PLUS = '90+',
  SIXTY_DAYS_PLUS = '60+',
  THIRTY_DAYS_PLUS = '30+',
  THIRTY_DAYS = '30',
  EMPTY_3 = '__EMPTY_3',
  EMPTY_4 = '__EMPTY_4',
  EMPTY_6 = '__EMPTY_6',
  EMPTY_7 = '__EMPTY_7',
  EMPTY_8 = '__EMPTY_8',
  EMPTY_9 = '__EMPTY_9',
  EMPTY_11 = '__EMPTY_11',
  TEN_RENT = 'TenRent',
  LATE_FEES = 'LateFees',
}

export enum Sequence {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

enum CommunicationStatus {
  ACTIVE = 'Active',
  NOT_ACTIVE = 'Not Active',
}

enum NumbersList {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

enum SYMBOLS {
  QUESTION_MARK = '?',
  PERCENTAGE = '%',
}

enum OptingStatuses {
  OPT_OUT = 'optOut',
  OPT_IN = 'optIn',
  NO_INDICATION = 'noIndication',
}

const DEACTIVATED_TENANT_STATUS = Object.freeze({
  status: false,
});

enum UserTypes {
  USER = 'User',
  SUPERVISOR = 'Supervisor',
  COLLEEN = 'Colleen',
}

enum PaymentMethods {
  OnlinePayments = 'online payments',
  MoneyOrder = 'money order',
  BankCheck = 'bank check',
  Cash = 'cash',
  CashiersCheck = "cashier's check",
  PersonalCheck = 'personal check',
  WIPS = 'WIPS',
  Paylease = 'Paylease',
  Flex = 'Flex',
  RentCafe = 'RentCafe',
  PayNearMeStation = 'Pay Near Me Station',
  MoneyGram = 'MoneyGram',
}

enum EnabledUntilDayOfTheMonth {
  DAY_1 = '1',
  DAY_2 = '2',
  DAY_3 = '3',
  DAY_4 = '4',
  DAY_5 = '5',
  DAY_6 = '6',
  DAY_7 = '7',
  DAY_8 = '8',
  DAY_9 = '9',
  DAY_10 = '10',
  DAY_11 = '11',
  DAY_12 = '12',
  DAY_13 = '13',
  DAY_14 = '14',
  DAY_15 = '15',
  DAY_16 = '16',
  DAY_17 = '17',
  DAY_18 = '18',
  DAY_19 = '19',
  DAY_20 = '20',
  DAY_21 = '21',
  DAY_22 = '22',
  DAY_23 = '23',
  DAY_24 = '24',
  DAY_25 = '25',
  DAY_26 = '26',
  DAY_27 = '27',
  DAY_28 = '28',
  DAY_29 = '29',
  DAY_30 = '30',
  DAY_31 = '31',
  END_OF_GRACE_PERIOD = 'End of Grace Period',
  FILING_DAY = 'Filing Day',
}

enum CollectionFilterTypes {
  OptOut = 'OptOut',
  OptIn = 'optIn',
  LateTenants = 'late tenants',
  AllTenants = 'all tenants',
  Unread = 'Unread',
  MarkAsPaid = 'Mark as Paid',
  Read = 'Read',
  Activate = 'Active',
  Deactivate = 'Deactivate',
  Alphabetic = 'Alphabetic',
  AssignedToMe = 'Assigned to me',
  Color = 'color',
  Balance = 'Balance',
  PayDate = 'Pay Date',
  LatePeriod = 'Late Period',
  LastInteraction = 'Last Interaction',
  TenantReply = 'Tenant Reply',
}

const SOLE_COMPANIES = 'Sole Companies';

export enum ConsoleColors {
  ORANGE = 'ORANGE',
  PURPLE = 'PURPLE',
  BLUE = 'BLUE',
}

const TIMEZONES = ['America/Los_Angeles', 'America/Denver', 'America/Chicago', 'America/New_York'];

const USER_SESSION_EXPIRATION_TIME: number = 48 * 60 * 60 * 1000; // in ms, 48 hours

export {
  CollectionFilterTypes,
  DashboardChartsSettingNames,
  Softwares,
  LedgerFieldNames,
  NumbersList,
  NotificationPace,
  SYMBOLS,
  MINUTE_IN_MILLISECONDS,
  OptingStatuses,
  DEACTIVATED_TENANT_STATUS,
  UserTypes,
  PaymentMethods,
  DAYS_OF_MONTH,
  YEARS,
  PAGINATOR_PAGE_SIZES,
  SOLE_COMPANIES,
  DEFAULT_UNTIL_DAY,
  EnabledUntilDayOfTheMonth,
  MIN_MINUTES_SINCE_LAST_SYNC_PRIOR_SEQUENCE_MESSAGE,
  CommunicationStatus,
  TIMEZONES,
  USER_SESSION_EXPIRATION_TIME,
};
