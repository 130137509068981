<div class="unauthorised-page">
  <div class="logo">
    <img src="assets/images/accounts/logo.svg" />
  </div>
  <div class="message">
    You are not authorized<br />
    to access this page
  </div>
  <div class="copyright" routerLink="/account/login">© Colleen AI Inc., {{ currentYear }}</div>
</div>
