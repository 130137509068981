import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import emailsUtils from '../../utils/emails.utils';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-cc-bcc-input',
  templateUrl: './cc-bcc-input.component.html',
  styleUrls: ['./cc-bcc-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
  ],
})
export class CcBccInputComponent implements OnInit {
  @Input() excludedEmails: string[] = [];
  @Output() selectedEmailsChange = new EventEmitter<string[]>();
  @Input() set emailValue(value: string) {
    if (value) {
      this.selectedChips.push(value);
      // Emit the updated chips to the parent component
      this.selectedEmailsChange.emit(this.selectedChips);
    }
  }
  @Input() isDisabled: boolean = false; // Add an input to control input disable state
  public separatorKeysCodes: number[] = [13, 188]; // Enter and comma keys
  public addOnBlur: boolean = true;
  public chipInputCtrl = new FormControl();
  public selectedChips: string[] = [];

  constructor() {}
  public ngOnInit(): void {}

  /**
   * Add a chip to the selected chips list.
   *
   * @param {MatChipInputEvent} event - The chip input event.
   * @returns {void}
   */
  public addChip(event: MatChipInputEvent): void {
    const value = event.value.trim(); // Trim any leading or trailing whitespace from the input value

    if (value) {
      const chips = value.split(/\s+/); // Split the value by one or more spaces into an array of chips
      const validChips = chips
        .filter((chip) => emailsUtils.isValidEmail(chip)) // Filter out valid email chips
        .filter((chip) => !this.selectedChips.includes(chip) && !this.excludedEmails.includes(chip)); // Filter out duplicate chips

      if (validChips.length > 0) {
        const updatedChips = [...this.selectedChips, ...validChips]; // Concatenate the valid chips with the existing selected chips
        this.selectedChips = updatedChips; // Update the selected chips list
        this.selectedEmailsChange.emit(updatedChips); // Emit an event with the updated chips list
      }
    }

    event.chipInput?.clear(); // Clear the chip input element
  }

  /**
   * Remove a chip from the selected chips list.
   *
   * @param {string} chip - The chip to remove.
   * @returns {void}
   */
  public removeChip(chip: string): void {
    const index = this.selectedChips.indexOf(chip); // Find the index of the chip in the selected chips list

    if (index > -1) {
      const chips = this.selectedChips.slice(); // Create a shallow copy of the selected chips list
      chips.splice(index, 1); // Remove the chip at the found index
      this.selectedChips = chips; // Update the selected chips list
      this.selectedEmailsChange.emit(chips); // Emit an event with the updated chips list
    }
  }
  public clearChips(): void {
    this.selectedChips = [];
    this.selectedEmailsChange.emit([]);
  }
}
