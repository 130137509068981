import { Component } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { AppService } from './providers/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(private appService: AppService) {
    initializeApp(environment.FIREBASE_CREDENTIAL);
    this.appService.initConfig();
  }
}
