<mat-form-field [class.disabled]="isDisabled" class="cc-bcc-input">
  <mat-chip-grid #chipGrid>
    <mat-chip-row
      [class.block]="chip"
      *ngFor="let chip of selectedChips"
      [removable]="!isDisabled"
      (removed)="removeChip(chip)"
    >
      <div class="clip-text">{{ chip }}</div>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>
  </mat-chip-grid>

  <input
    matInput
    placeholder="Emails"
    [matChipInputFor]="chipGrid"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    [matChipInputAddOnBlur]="addOnBlur"
    (matChipInputTokenEnd)="addChip($event)"
    [formControl]="chipInputCtrl"
    [readonly]="isDisabled"
  />
</mat-form-field>
