<div class="image-picker-container">
  <div class="image-picker">
    <button matRipple class="close" (click)="dialogRef.close()">
      <img src="assets/images/cross14x14%20.svg" />
    </button>
    <div *ngIf="!croppedImage">
      <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <img class="blank" src="assets/images/blank.svg" />
          <div class="drag-text">Drag photo here to upload</div>
          <div class="or">or</div>
          <button matRipple class="button primary-button large" (click)="openFileSelector()">
            {{ photoText }}
          </button>
        </ng-template>
      </ngx-file-drop>
      <div class="error" *ngIf="isInvalid">Please select a valid photo</div>
    </div>
    <div class="cropped" [class.has-image]="croppedImage">
      <image-cropper
        [containWithinAspectRatio]="true"
        [imageChangedEvent]="imageChangedEvent"
        [backgroundColor]="'#FFFFFF'"
        [maintainAspectRatio]="data.maintainAspectRatio"
        [aspectRatio]="data.aspectRatio || 4 / 4"
        [resizeToWidth]="data.resizeToWidth || 128"
        [format]="data.format"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="isInvalid = false"
        (loadImageFailed)="isInvalid = true"
      ></image-cropper>
      <button matRipple class="button primary-button" (click)="save()" *ngIf="croppedImage">Save</button>
    </div>
  </div>
</div>
