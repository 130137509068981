import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainGuard } from './guards/main.guard';
import { AccountGuard } from './guards/account.guard';
import { HttpService } from './providers/http.service';
import { ToolsService } from './providers/tools.service';
import { AppComponent } from './app.component';
import { AlertComponent } from './components/alert/alert.component';
import { PromptComponent } from './components/prompt/prompt.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { RentmanagerComponent } from './components/rentmanager/rentmanager.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { FileService } from './providers/file.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AngularFireRemoteConfigModule, SETTINGS } from '@angular/fire/compat/remote-config';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { CcBccInputComponent } from './components/cc-bcc-input/cc-bcc-input.component';

const appRoutes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then((m) => m.AccountModule),
    canActivate: [AccountGuard],
  },
  {
    path: 'main',
    loadChildren: () => import('./modules/main/main.module').then((m) => m.MainModule),
    canActivateChild: [MainGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  { path: '**', redirectTo: 'unauthorized', pathMatch: 'full' },
  { path: '', redirectTo: 'unauthorized', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    PromptComponent,
    ConfirmComponent,
    LoadingComponent,
    RentmanagerComponent,
    UnauthorizedComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: false }),
    NgxMaskModule.forRoot(),
    FormsModule,
    DragDropModule,
    BrowserModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.FIREBASE_CREDENTIAL),
    AngularFireRemoteConfigModule,
    MatRippleModule,
    CcBccInputComponent,
  ],
  providers: [
    ToolsService,
    FileService,
    HttpService,
    AccountGuard,
    MainGuard,
    { provide: SETTINGS, useValue: { minimumFetchIntervalMillis: 3600000 } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
